<template>
  <div class="">
    <button @click="openModal()" class="duplicateProducts">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-globe"
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="2" y1="12" x2="22" y2="12" />
        <path
          d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
        /></svg
      >Language
    </button>

    <Modal
      id="translationTitle"
      ref="translationTitle"
      :title="'Title Translation'"
      :size="'md'"
    >
      <form v-if="!isProductLoading" ref="myForm" @submit.prevent="submitForm()">
        <div
          class="mb-3"
          v-for="(translation, index) in translations"
          :key="index"
        >
          <div class="d-flex justify-content-between px-2">
            <div class="sub-heading" v-b-toggle="`collapse-${index}`">
              <span class="open-language-chevron mr-2">
                <i class="fa-solid fa-angle-right"></i> </span
              >{{ getSelectedTranslationText(index) || "Select Language" }}
            </div>
            <div
              title="Close"
              @click="translations.splice(index, 1)"
              class="close-button"
            >
              <i aria-hidden="true" class="fa fa-times"></i>
            </div>
          </div>

          <b-collapse :id="`collapse-${index}`">
            <div
              class="px-3 position-relative"
              style="
                background: rgb(247, 247, 247);
                padding: 20px 0px;
                border-radius: 12px;
              "
            >
              <div class="row">
                <div class="col-12">
                  <p class="headText m-0 mb-1">Language:</p>
                  <div
                    class="d-flex quizell-type justify-content-center align-items-center"
                  >
                    <select
                      type="number"
                    
                      v-model="translation.language_id"
                      required
                      class="w-100 bg-white quizell-form-control"
                    >
                      <option value="" disabled>Select Language</option>
                      <option
                    
                        :value="language.id"
                        v-for="language in languages"
                        :key="language.id"
                      >
                        {{ language.langText + " - " + language.lang }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <p class="headText m-0 mb-1">Title:</p>
                  <div
                    class="d-flex quizell-type justify-content-center align-items-center"
                  >
                    <input
                      type="text"
                      @change="changeInputColor($event)"
                      v-model.trim="translation.title"
                      required
                      class="w-100 bg-white quizell-form-control px-2"
                      placeholder="Title"
                    />
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <p class="headText m-0 mb-1">Detail Link:</p>
                  <div
                    class="d-flex quizell-type justify-content-center align-items-center"
                  >
                    <input
                      type="url"
                      @change="changeInputColor($event)"
                      v-model.trim="translation.detail_link"
                      class="w-100 bg-white quizell-form-control px-2"
                      placeholder="Detail Link"
                    />
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <p class="headText m-0 mb-1">Price:</p>
                  <div
                    class="d-flex quizell-type justify-content-center gap align-items-center"
                  >
                    <input
                      type="text"
                      @change="changeInputColor($event)"
                      v-model.number="translation.price"
                      required
                      class="w-100 bg-white quizell-form-control px-2"
                      placeholder="Price"
                    />
                    <select name="" v-model="translation.price_symbol" class="quizell-form-control" id="">
                      <option
        v-for="(details, lang) in currencySymbols"
        :key="lang"
        :value="details.sign"
      >
       {{ details.sign }} - {{ details.country }}
      </option>
                    </select>
                  </div>
                </div>
                <div class="col-12 mt-3">
                  <p class="headText m-0 mb-1">Bullet Description:</p>
                  <MultiFilter
                    class="tag-filter"
                    :selectedItems="translation.bullet_description"
                    :addInList="true"
                    :isLoading="isProductLoading"
                    :items="allBullets"
                    @add="addBullet($event, index)"
                    @input="(e) => (translation.bullet_description = e)"
                  ></MultiFilter>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="" v-show="translations.length <= 0">
          <EmptyTable>
            <template #title> Title Translation </template>
            <template #description>
              <div class=""></div>
            </template>
          </EmptyTable>
        </div>
        <div>
          <div class="d-flex justify-content-end w-100 mt-5">
            <button
              class="btn btn-link"
              type="button"
              @click.stop="addLanguage()"
            >
              + Add
            </button>
            <QuizellButton
              :isLoading="isSubmit"
              type="submit"
              @click="checkValidity()"
              >Save</QuizellButton
            >
          </div>
        </div>
      </form>
      <div class="" v-else>
                <div 
        class="integrationImgae bg-preload mt-3" v-for="index in 2" :key="index"
        style="height: 70px; width: 100%; border-radius: 14px">
        <div class="into-preload"></div>
      </div>
            </div>
    </Modal>
  </div>
</template>

<script>
import QuizellButton from "../Layout/Button.vue";
import Modal from "../Layout/Modal.vue";
import MultiFilter from "../Layout/MultiFilter.vue";
import axios from "axios";
import EmptyTable from "../Layout/EmptyTable.vue";
import { uniq } from "lodash";
export default {
  components: {
    Modal,
    MultiFilter,
    QuizellButton,
    EmptyTable,
  },
  props: {
    productId: {
      type: Number,
    },
  },
  computed: {
    getUniqueLanguages() {
      return this.languages.filter(
        ({ id }) =>
          !this.translations.some(({ language_id }) => id == language_id)
      );
    },
  },
  data() {
    return {
      translations: [
        {
          language_id: 2,
          title: "",
          bullet_description: [],
        },
      ],
      selectedBullets: [],
      isProductLoading: true,
      allBullets: [],
      languages: [],
      isSubmit: false,
      currencySymbols:[]
    };
  },
  mounted() {
  
  },
  methods: {

   async openModal(){
    this.isProductLoading= true;
        this.$refs.translationTitle.open();
        this.translations=[{
        language_id: 1,
        title: "",
        bullet_description: [],
      }]
      this.getBulletsDescription();
      this.getLanguages();
     await this.getTranslation();
     this.isProductLoading=false;
    },
    changeInputColor(input) {
      input.target.style.borderColor = "#d2d8e0";
    },
    checkValidity() {
      const form = this.$refs.myForm;

      if (!form.checkValidity()) {
        const invalidInputs = Array.from(form.querySelectorAll(":invalid"));
        if (invalidInputs.length > 0) {
          // Focus on the first invalid input
          invalidInputs[0].style.borderColor = "#f20000";
          this.$toasted.show("Please fill out the required fields.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        return; // Prevent submission
      }
    },
    async submitForm() {
      this.isSubmit = true;
      try {
        const translations = [];
        this.translations.forEach((translation) => {
          translations.push({
            language_id: translation.language_id,
            title: translation.title,
            detail_link:translation.detail_link,
            price:translation.price,
            price_symbol:translation.price_symbol,
            bullet_description: translation.bullet_description.map(
              (bullet) => bullet.title
            ),
          });
        });
        const response = await axios.post(
          "store/product-translation/" + this.productId,
          { translations: translations }
        );

        if (response.status == 200) {
          this.$refs.translationTitle.close();
          this.$toasted.show("Title translation saved successfully", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (e) {
        this.$toasted.show("Error Occurred", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw e;
      } finally {
        this.isSubmit = false;
      }
    },
    addLanguage() {
      this.translations.push({
        language_id: 1,
        title: "",
        bullet_description: [],
 
      });
    },
  async  getTranslation() 
    {  this.translations=[]
        try {
        const response = await axios.get("product/translations",{params:{product_id:this.productId}});
        this.currencySymbols = response.data.data.currencies;
        response.data.data.productTranslations.forEach((translation) => {
            this.translations.push({
            language_id: translation.language_id,
            title: translation.title,
            price:translation.price,
            price_symbol:translation.price_symbol,
            detail_link:translation.detail_link,
            bullet_description: JSON.parse(translation.bullet_description).map(
              (bullet) => {return {title:bullet}}
            ),
          });
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    getSelectedTranslationText(index) {
      const language = this.languages.find(
        (lang) => this.translations[index].language_id == lang.id
      );
      return language ? language.langText : "";
    },
     addBullet(data, index) {
       this.translations[index].bullet_description=[...this.translations[index].bullet_description,data];
        this.translations[index].bullet_description = uniq(this.translations[index].bullet_description)
    },
    async getBulletsDescription() {
      try {
        const response = await axios.get("/products/bullets/list", {
          params: { page: 1, per_page: 100000 },
        });

        this.allBullets = response.data.data.data.map((bullet) => {
          return { title: bullet.title };
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async getLanguages() {
      try {
        const response = await axios.get("/languages");
        this.languages = response.data.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
  },
};
</script>

<style scoped>
.sub-heading {
  color: var(--grey-button);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.close-button {
  background: #e91e63;
  color: white;
  height: 16px;
  width: 16px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 12px;
}
.tag-filter >>> .popover-list {
  max-height: 90px !important;
}
.duplicateProducts {
  padding: 7px 12px;
  display: flex;
  gap: 4px;
  border-radius: 6px;
  background: var(--white);
  border: 1px solid var(--border-color);
  color: var(--grey-button);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  transition: 0.3s ease all;
}
.duplicateProducts:hover {
  background: var(--grey-button);
  color: var(--white);
}
.headText {
  color: rgb(115, 115, 141);
  font-size: 14px;
}
</style>
<style>
.open-language-chevron {
  display: inline-block;
  transition: 0.3s ease all;
}
:not(.collapsed) > .open-language-chevron {
  transform: rotate(90deg);
}
</style>
